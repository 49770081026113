import axios from "axios";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  ILandingOrder,
  ILandingOrderResponse,
  IOrder,
  ISignUp,
  IProductSentencesResponse,
  INewOrder,
} from "./interfaces";
import {
  IPackage,
  IInvoiceResponse,
  IPaymentResponse,
  IFinancesResponse,
  IFinanceFilter,
  IPackageStatistics,
  IPackageStatisticsResponse,
  IAvailablePromoPackage,
  IDexSummerPromo,
  IEmirexPaymentRequest,
  IEmirexPaymentData,
} from "./interfaces/mlm";

function authHeaders(token: string) {
  if (token) {
    return {
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
  } else {
    return {};
  }
}

function authUploadHeaders(token: string) {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `JWT ${token}`,
    },
  };
}

export type FetchRequestInit = Omit<RequestInit, "method" | "body"> & {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  body?: any;
};

export const api = {
  async authFetch(token: string, input: string, init: FetchRequestInit = {}) {
    const fullUrl = apiUrl + input;
    const headers = {
      ...init.headers,
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    };

    const method = init.method || "GET";

    const fetchOptions: RequestInit = {
      ...init,
      method,
      headers,
    };

    if (init.body && method !== "GET") {
      fetchOptions.body = JSON.stringify(init.body);
    }

    return fetch(fullUrl, fetchOptions);
  },
  async submitlogIn(data: {
    username: string;
    password: string;
    code: string;
    timezone_name?: any;
  }) {
    return axios.post<{ token: string }>(
      `${apiUrl}/api/v1/accounts/login/`,
      data
    );
  },
  async logInByToken(authToken: string, language: string) {
    const params = new URLSearchParams();
    params.append("token", authToken);
    params.append("language", language);

    return axios.post(`${apiUrl}/api/v1/accounts/token-auth/`, params);
  },
  async submitSignUp(data: ISignUp) {
    return axios.post<{ token: string }>(
      `${apiUrl}/api/v1/accounts/register/`,
      data
    );
  },
  async checkPromocode(data: { referral: string }) {
    return axios.post<{ id: number }>(
      `${apiUrl}/api/v1/accounts/check_referral_id/`,
      data
    );
  },
  async submitRecoverPassword(data: { email: string }) {
    return axios.post(`${apiUrl}/accounts/email_password_reset/`, data);
  },
  async submitResetPassword(data: { token: string; password: string }) {
    return axios.post<{ status: string }>(
      `${apiUrl}/accounts/email_password_reset/confirm/`,
      data
    );
  },
  async submitSetLanguage(token: string, data: { language: string }) {
    return axios.post(
      `${apiUrl}/api/v1/accounts/set_language/`,
      data,
      authHeaders(token)
    );
  },
  async submitLandingOrder(data: ILandingOrder) {
    return axios.post<ILandingOrderResponse>(
      `${apiUrl}/api/v1/products/create_order_and_register_user/`,
      data
    );
  },
  async submitNewOrder(token: string, data: INewOrder) {
    return axios.post<IOrder>(
      `${apiUrl}/api/v1/products/create_order/`,
      data,
      authHeaders(token)
    );
  },
  // async getMe(token: string) {
  //   return axios.get<IUserProfile>(`${apiUrl}/api/v1/accounts/me/`, authHeaders(token));
  // },
  async getMe(token: string) {
    return axios.post<IUserProfile>(
      `${apiUrl}/api/v1/accounts/info/`,
      {},
      authHeaders(token)
    );
  },
  async updateUserInfo(token: string, data: { code_of_etics_status?: string }) {
    return axios.patch(
      `${apiUrl}/api/v1/accounts/info/`,
      data,
      authHeaders(token)
    );
  },
  async updateMe(token: string, data: any) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/accounts/me/`,
      data,
      authHeaders(token)
    );
  },
  async updateUserAvatar(token: string, data: FormData) {
    return axios.post<IUserProfile>(
      `${apiUrl}/api/v1/accounts/me/`,
      data,
      authUploadHeaders(token)
    );
  },
  async unsubscribeFromBot(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/chatbot/send_unsubscribe_message/`,
      {},
      authHeaders(token)
    );
  },
  async getPackagesList(token: string) {
    return axios.get<IPackage[]>(
      `${apiUrl}/api/v1/mlm/packages/`,
      authHeaders(token)
    );
  },
  async getPackagesTerms(token: string) {
    return axios.get(
      `${apiUrl}/api/v1/mlm/packages/terms/`,
      authHeaders(token)
    );
  },
  async getActivePackage(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/package/active/`,
      {},
      authHeaders(token)
    );
  },
  async getQualification(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/get_qualification/`,
      {},
      authHeaders(token)
    );
  },
  async makeProfixoneLink(token: string) {
    return axios.post<{ url: string }>(
      `${apiUrl}/api/v1/accounts/make_profixone_link/`,
      {},
      authHeaders(token)
    );
  },
  async getCompanyStats(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/company_stats/`,
      {},
      authHeaders(token)
    );
  },
  // async getOrdersList(token: string) {
  //   return axios.get<IOrder[]>(`${apiUrl}/api/v1/products/orders/`, authHeaders(token));
  // },
  async getOrCreateInvoice(
    token: string,
    data: { package: number; standart: string; token?: string }
  ) {
    return axios.post<IInvoiceResponse>(
      `${apiUrl}/api/v1/mlm/package/purchase/request/`,
      data,
      authHeaders(token)
    );
  },
  async processConfirmInvoice(token: string, data: { invoice: number }) {
    return axios.post<IInvoiceResponse>(
      `${apiUrl}/api/v1/mlm/package/purchase/confirm/`,
      data,
      authHeaders(token)
    );
  },
  // async getOrCreateInvoice(token: string, data: {package: number, standart: string, token?: string}) {
  //   return axios.post<IInvoiceResponse>(`${apiUrl}/api/v1/mlm/package/purchase/`, data, authHeaders(token));
  // },
  // async getOrCreateUpgradeInvoice(token: string, data: {package: number, standart: string, token?: string}) {
  //   return axios.post<IInvoiceResponse>(`${apiUrl}/api/v1/mlm/package/upgrade/`, data, authHeaders(token));
  // },
  async getOrCreateOrderInvoice(
    token: string,
    data: { order?: number | null; standart: string; token?: string }
  ) {
    return axios.post<IInvoiceResponse>(
      `${apiUrl}/api/v1/payments/get_or_create_invoice/`,
      data,
      authHeaders(token)
    );
  },
  async getPaymentStatus(
    token: string,
    data: { address: string; invoice: number }
  ) {
    return axios.post<IPaymentResponse>(
      `${apiUrl}/api/v1/payments/get_payment_status/`,
      data,
      authHeaders(token)
    );
  },
  async processPurchaseByUnit(token: string, data: { invoice: number }) {
    return axios.post(
      `${apiUrl}/api/v1/payments/process_purchase_by_unit/`,
      data,
      authHeaders(token)
    );
  },
  async getTransactionsList(
    token: string,
    data: IFinanceFilter,
    rowQuery: string
  ) {
    return axios.post<IFinancesResponse>(
      `${apiUrl}/api/v1/payments/finances/${rowQuery}`,
      data,
      authHeaders(token)
    );
  },
  async requestEmirexPayment(token: string, data: IEmirexPaymentRequest) {
    return axios.post<IEmirexPaymentData>(
      `${apiUrl}/api/v1/cardpayment/test_endpoint_carpayment/`,
      data,
      authHeaders(token)
    );
  },
  async getEmirexEuroRate(token: string) {
    return axios.post<{ usdt_euro_rate: number }>(
      `${apiUrl}/api/v1/cardpayment/usdt_euro_rate/`,
      {},
      authHeaders(token)
    );
  },
  async validateDexnetAddress(token: string, address: string) {
    return axios.post(
      `${apiUrl}/api/v1/withdraw/withdraw_address_dexnet_validation/`,
      { address: address },
      authHeaders(token)
    );
  },
  async checkDexfreedomTokenInfo(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/withdraw/dft_to_send_to_dexnet/`,
      {},
      authHeaders(token)
    );
  },
  async submitWithdrawRequest(
    token: string,
    data: {
      amount: number;
      standart: string;
      token: string;
      wallet_address: string;
      security_code: string;
    }
  ) {
    return axios.post(
      `${apiUrl}/api/v1/withdraw/request/`,
      data,
      authHeaders(token)
    );
  },
  async getProductSentencesSet(
    token: string,
    data: { product_sentences_set: string; promocode?: string }
  ) {
    return axios.post<IProductSentencesResponse>(
      `${apiUrl}/api/v1/products/products/product_sentences_set/`,
      data,
      authHeaders(token)
    );
  },
  async getUserBinaryObject(token: string, data: { user: number }) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/get-binary-object/`,
      data,
      authHeaders(token)
    );
  },
  async getUserBinaryTree(token: string, data: { user: number }) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/get-binary-tree/`,
      data,
      authHeaders(token)
    );
  },
  async getUserRegistrationsTable(
    token: string,
    data: { user: number },
    rowQuery
  ) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/get-registrations-tables/${rowQuery}`,
      data,
      authHeaders(token)
    );
  },
  async getUserStructureList(token: string) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/structure_list/`,
      {},
      authHeaders(token)
    );
  },
  async setUserBinaryStructureSide(token: string, data: { side: string }) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/set-binary-structure-side/`,
      data,
      authHeaders(token)
    );
  },
  async getPurchaseStatisticsByPackages(token: string, data: any) {
    return axios.post<IPackageStatisticsResponse>(
      `${apiUrl}/api/v1/mlm/get_purchase_statistics_by_packages/`,
      data,
      authHeaders(token)
    );
  },
  async getAllConfigs(token: string) {
    return axios.get(
      `${apiUrl}/api/v1/configs/get_all_configs/`,
      authHeaders(token)
    );
  },
  async submitUpdateOrder(
    token: string,
    data: {
      order: number;
      delivery_address?: string;
      starlink_kit?: boolean;
      promocode?: string;
    }
  ) {
    return axios.post<IProductSentencesResponse>(
      `${apiUrl}/api/v1/products/update_order/`,
      data,
      authHeaders(token)
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(
      `${apiUrl}/api/v1/users/`,
      authHeaders(token)
    );
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getAvailablePromoPackages(token: string) {
    return axios.get<IAvailablePromoPackage[]>(
      `${apiUrl}/api/v1/promotions/promocodes/`,
      authHeaders(token)
    );
  },
  async submitActivatePromocode(token: string, data: { promocode: string }) {
    return axios.post<{ success: boolean }>(
      `${apiUrl}/api/v1/promotions/promocode/activate/`,
      data,
      authHeaders(token)
    );
  },
  async submitCreatePromocode(
    token: string,
    data: { promocode: number; standart: string; token: string }
  ) {
    return axios.post<{ promocode: string }>(
      `${apiUrl}/api/v1/promotions/promocode/purchase/request/`,
      data,
      authHeaders(token)
    );
  },
  async getFirstLineUsers(
    token: string,
    data: {
      user: number;
      search?: string;
      users_count_first_line_min?: number;
      users_count_first_line_max?: number;
      level_min?: number;
      level_max?: number;
    },
    rowQuery: string
  ) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/first-line/${rowQuery}`,
      data,
      authHeaders(token)
    );
  },
  async getFirstLineUsersForUser(
    token: string,
    data: { user: number; product?: number | null }
  ) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/first-line-for-user/`,
      data,
      authHeaders(token)
    );
  },
  async getReferralStructureInfo(token: string, data: any) {
    return axios.post(
      `${apiUrl}/api/v1/mlm/referral_structure_info/`,
      data,
      authHeaders(token)
    );
  },
  async getDexSummerPromoStatus(token: string, data: any) {
    return axios.post<IDexSummerPromo>(
      `${apiUrl}/api/v1/mlm/get_dex_summer_promo_status/`,
      data,
      authHeaders(token)
    );
  },
};
